@import '../../../styles/customMediaQueries.css';

.root {
  /* composes: marketplaceModalFormRootStyles from global; */

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.nextButton{
  margin-top: 16px;
}
.joinusHeading{
  margin-bottom: 24px;
}
.defaultUserFields {
}
.marginBottom, .locationAddress, .building{
  margin-bottom: 24px;
}
.socialButtonsOr{
  margin: 16px auto 16px;
  text-align: center;
}
.signUpLink{
  text-align: center;
}
.name {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}

.row,
.customFields {
  margin-bottom: 24px;
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
 margin-bottom: 24px;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}
.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  & input {
    display: none;
  }
  & .subTitle {
    color: rgb(51, 51, 51);
    margin: 0 0 24px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: 220px;
  height: 220px;
  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: hsl(240 3% 98%);
  cursor: pointer;

  @media (min-width: 768px) {
    width: 250px;
    height: 250px;
  }
  & img, & embed {
    width: 250px;
    height: 250px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 4px;
    @media (max-width: 767px) {
      width: 220px;
      height: 220px;
    }
  }
}
.spinner {
  width: 35px;
  height: 35px;
  stroke: gray;
  stroke-width: 3px;
  top: 10px;
  position: absolute;
  top: 44%;
  left: 44%;
}
.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: hsl(240 3% 90%);
  border-width: 2px;
  padding: 12px;
  transition: ease-in-out 0.1s;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  &:hover {
    border-color: hsl(240 3% 70%);
  }
}
.changeAvatar {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: #fff;
  /* border: solid 1px #e5e5e6; */
  border-radius: 100px;
  margin-top: 0;
  margin-bottom: 0;
  & svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    border: solid 1px #b0b0b5;
  }
}